import axios from "axios";

axios.defaults.withCredentials = true;

let httpApi = axios.create({
  baseURL: "//" + process.env.VUE_APP_API + "/api/v1",
  headers: {
    Accept: "application/json"
  }
});

export default httpApi;
