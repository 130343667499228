import httpApi from "@/http/axios";

const state = {
  userSetting: []
};

const mutations = {
  setUserSetting(state, parses) {
    state.userSetting = parses;
  }
};

const actions = {
  getUserSetting({ commit }) {
    return httpApi.get("user/setting").then(response => {
      commit("setUserSetting", response.data.data);
    });
  }
};

const getters = {
  userSetting: state => state.userSetting
};

export default {
  state,
  actions,
  mutations,
  getters
};
