import httpApi from "@/http/axios";

const state = {
  players: []
};

const mutations = {
  setPlayers(state, players) {
    state.players = players;
  }
};

const actions = {
  getPlayers({ commit }) {
    return httpApi.get("players").then(response => {
      commit("setPlayers", response.data.data);
    });
  }
};

const getters = {
  players: state => state.players
};

export default {
  state,
  actions,
  mutations,
  getters
};
