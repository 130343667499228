<template>
  <div id="app" class="z-theme-dark_">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      registration: null
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", this.update);
    }
  },
  methods: {
    async update() {
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  }
};
</script>
