import httpApi from "@/http/axios";

const state = {
  user: null
};

const mutations = {
  setUserData(state, userData) {
    state.user = userData;
    localStorage.setItem("userInfo", JSON.stringify(userData));

    let expires = 86400 + new Date().getTime() / 1000; //seconds
    localStorage.setItem("expires_in", expires);
  },
  clearUserData() {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("expires_in");
    window.document.cookie =
      "laravel_session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
};

const actions = {
  login({ commit }, payload) {
    return httpApi
      .get(`//${process.env.VUE_APP_API}/sanctum/csrf-cookie`)
      .then(response => {
        console.log(response);
        return httpApi
          .post(`//${process.env.VUE_APP_API}/login`, payload)
          .then(({ data }) => {
            commit("setUserData", data.data);
          })
          .catch(err => {
            if (err.response && err.response.status == 403) {
              return Promise.reject("Sai thông tin đăng nhập!");
              // console.log(err.response.data);
              // console.log(err.response.status);
              // console.log(err.response.headers);
            }
          });
      });
  },

  logout({ commit }) {
    commit("clearUserData");
  },

  verify_auth({ commit }) {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) return;

    return new Promise((resolve, reject) => {
      let expires_in = parseInt(localStorage.getItem("expires_in"));
      if (expires_in - new Date().getTime() / 1000 <= 0) {
        commit("clearUserData");
        reject();
      } else {
        resolve();
      }
    });
  }
};

const getters = {
  isLogged: state => !!state.user,
  currentUser: state => state.user
};

export default {
  state,
  actions,
  mutations,
  getters
};
