import httpApi from "@/http/axios";
import { groupBy } from "lodash";

const state = {
  game: {},
  channel: {},
  prop: {}
};

const mutations = {
  setGameMapping(state, game) {
    state.game = game;
  },
  setChannelMapping(state, channel) {
    state.channel = channel;
  },
  setPropMapping(state, prop) {
    state.prop = prop;
  }
};

const actions = {
  getGameMapping({ commit }) {
    return httpApi.get("game-mapping").then(response => {
      commit(
        "setGameMapping",
        groupBy(response.data.data.game, e => e.id)
      );
      commit(
        "setChannelMapping",
        groupBy(response.data.data.channel, e => e.id)
      );
      commit(
        "setPropMapping",
        groupBy(response.data.data.prop, e => e.id)
      );
    });
  }
};

const getters = {
  game: state => state.game,
  channel: state => state.channel,
  prop: state => state.prop
};

export default {
  state,
  actions,
  mutations,
  getters
};
