import httpApi from "@/http/axios";

const state = {
  parseTypes: []
};

const mutations = {
  setParseTypes(state, parses) {
    state.parseTypes = parses;
  }
};

const actions = {
  getParseTypes({ commit }) {
    return httpApi.get("setting/parses").then(response => {
      commit("setParseTypes", response.data.data);
    });
  }
};

const getters = {
  parseTypes: state => state.parseTypes
};

export default {
  state,
  actions,
  mutations,
  getters
};
